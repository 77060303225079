<template>
<div>
    <v-snackbar v-model="snackbar" :top=true :color="color" :timeout=6000>
      <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

  <v-data-table
    :headers="headers"
    :items="link_super_master_list"
    class="elevation-1"
     :search="search"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Link</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details>
          </v-text-field>
          <span style="width: 20px"></span>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">Add Link</v-btn>
          </template>
          <v-card>
            <v-form v-model="valid">
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="editedItem.link_id" 
                    persistent-hint
                    :hint="editedIndex==-1?'For example :: 1, 2, 3':'For example ::  1, 2, 3'"
                    label="Enter Link ID"
                    :rules="[v => !!v || 'required']"
                    >
                    </v-text-field>
                  </v-col>
                  
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="editedItem.link_name" 
                    persistent-hint
                    :hint="editedIndex==-1?'For example :: Admission form, Fees payment':'For example ::  Admission form, Fees payment'"
                    label="Enter Link Name"
                    :rules="[v => !!v || 'required']"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="editedItem.link" 
                    persistent-hint
                    :hint="editedIndex==-1?'For example :: Admission/form, Accounts/payment':'For example ::  Admission/form, Accounts/payment'"
                    label="Enter Link"
                    :rules="[v => !!v || 'required']"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="editedItem.icon" 
                    persistent-hint
                    :hint="editedIndex==-1?'For example ::mdi-account-key,mdi-content-copy':'For example ::mdi-account-key,mdi-content-copy'"
                    label="Enter Link Icon Name"
                    :rules="[v => !!v || 'required']"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12"  v-if='editedIndex == -1'>	
	                    <v-autocomplete	
	                    :items="user_type_super_master_list"
                      v-model="editedItem.user_type_super_master_list"
	                    label="Select User Type"
                      :rules="[v => !!v || 'required']"	
	                    ></v-autocomplete>	
	                  </v-col>
                  <v-col cols="12" sm="12" md="12" v-if='editedIndex != -1'>
                    <v-text-field
                      v-model="editedItem.user_type.name"
                      persistent-hint
                      :hint="editedIndex==-1?'For example :: Employee, Parent':'For example ::  Employee, Parent'"
                      label="User Type"
                      
                    ></v-text-field>
                  </v-col>

                   <v-col cols="12" sm="12" md="6" v-if='editedIndex != -1'>
                     <v-checkbox
                        dense
                        v-model="editedItem.isactive"
                        color="success"
                        label="Active (For All Tenant)"
                      ></v-checkbox>
                  </v-col>  
                </v-row>
              </v-container>
            </v-card-text>
          
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="primary darken-1"  @click="save" :disabled="!valid">Save</v-btn>
            </v-card-actions>
             </v-form>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

         <template v-slot:item.isactive="{ item }">
            <span v-if="item.isactive">
              <v-icon  class="mr-2  v-icon-size" color="success">mdi-check-circle-outline</v-icon>
            </span>
            <span v-else>
              <v-icon  class="mr-2  v-icon-size" color="error">mdi-close-circle-outline</v-icon>
            </span>
          </template>

           <template v-slot:item.for_mobile="{ item }">
            <span v-if="item.for_mobile">
              <v-icon  class="mr-2  v-icon-size" color="success">mdi-check-circle-outline</v-icon>
            </span>
            <span v-else>
              <v-icon  class="mr-2  v-icon-size" color="error">mdi-close-circle-outline</v-icon>
            </span>
          </template>

    <template v-slot:item.action="{ item }">
     <div>
        <v-avatar style="" size="25" class="edit-avatar">
          <v-icon class="edit-v-icon mr-2" @click="editItem(item)">edit</v-icon>
       </v-avatar>
      </div>
    </template>
  </v-data-table>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
</div>
</template>

<script>
import axios from 'axios'
  export default {
    data: () => ({
      valid: false,
      overlay: false,
    errorMsg: {
      link_name: false
    },
      snackbar_msg: "",
      color: "",
      snackbar: false,
      search:'',
      dialog: false,
      headers: [
        {
          text: 'Link Id',
          align: 'left',
          sortable: false,
          value: 'link_id',
        },
        {
          text: 'Link Name',
          align: 'left',
          sortable: false,
          value: 'link_name',
        },
        {
          text: 'Link',
          align: 'left',
          sortable: false,
          value: 'link',
        },
        {
          text: 'Icon',
          align: 'left',
          sortable: false,
          value: 'icon',
        },
        {
          text: 'Is Active',
          align: 'left',
          sortable: false,
          value: 'isactive',
        },
        {
          text: 'User Type',
          align: 'left',
          sortable: false,
          value: 'user_type.name',
        },
        { text: 'Actions', 
          value: 'action', 
          sortable: false 
        },
      ],
      link_super_master_list: [],
      user_type_super_master_list:[],
      editedIndex: -1,
      editedItem: {
        adminLinkid: '',
        link_id: '',
        link_name: '',
        link: '',
        icon: '',
        user_type: '',
        isactive:true,
        for_mobile:true  
      },
      defaultItem: {
        adminLinkid: '',
        link_id: '',
        link_name: '',
        link: '',
        icon: '',
        user_type: '',
        isactive:true,
        for_mobile:true
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Link' : 'Edit Link'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      overlay (val) {
        val(() => {
          this.overlay = false
        }, )
      },
    },

    mounted(){
        this.onLoad()
     },
    methods: {
      onLoad(){
        this.overlay=true;
          axios
        .post("/Admin/adminLink")
        .then(res => {
          if (res.data.msg == "200") {
            if(this.load = true){
              this.overlay=false;
              this.link_super_master_list = res.data.link_super_master_list;
              this.user_type_super_master_list = res.data.user_type_super_master_list;
            }
          } else {
          }
        })
        .catch(error => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          // window.console.log(error);
        })
        .finally(()=>{ 
          overlay=false;
        });
      },
    initializeErrorMsgFalse() {
      this.errorMsg.standard = false;
    },

    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },

    editItem (item) {
        this.editedIndex = this.link_super_master_list.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
    },
      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      save() {
        if (this.editedIndex > -1) {
          if (this.isValidated()) {
            axios
              .post("/Admin/editAdminLink", this.editedItem)
              .then(res => {
                if (res.data.status.code == "SUCCESS") {
                  Object.assign(
                    this.link_super_master_list[this.editedIndex],
                    this.editedItem
                  );
                  this.showSnackbar(
                    "#4caf50",
                    "Link Updated Successfully..."
                  ); // show snackbar on success
                }
                else if (res.data.status.code == "NA") {
                  this.showSnackbar("#b71c1c", "Link Already Present!!!"); // show snackbar on error
                }
              })
              .catch(error => {
                window.console.log(error);
              });

            this.close();
          }
        } else {
          if (this.isValidated()) {
            axios
              .post("/Admin/saveAdminLink", this.editedItem)
              .then(res => {
                if (res.data.status.code == "SUCCESS") {
                  this.link_super_master_list.push(this.editedItem);
                  this.showSnackbar("#4caf50", "Link Added Successfully..."); // show snackbar on success
                  this.onLoad()
                } else if (res.data.status.code == "NA") {
                  this.showSnackbar("#b71c1c", "Link Already Present!!!"); // show snackbar on error
                }
              })
              .catch(error => {
                window.console.log(error);
              });

            this.close();
          }
        }
      }, // end of save()

      isValidated() {
        if (this.editedItem.link_name) {
          return true;
        } else {
          if (!this.editedItem.link_name) {
            this.errorMsg.link_name = true;
          }

          return false;
        }
      } // .....end of isValidated()
    },
  }
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
.v-icon-size{
  font-size: 20px;
}
.edit-avatar{
  transition: 0.3s;
  border: 1px solid #b0bec5;
}
.edit-avatar:hover{
  background-color: #b0bec5;
}
.edit-v-icon{
  padding-left: 6px;
  font-size: 15px;
}
.edit-v-icon:hover{
  color: white;
}
</style>